<template>

<div class="preview" :class="{'is-managing': isManaging}">

	<div class="preview-background" />

	<div class="preview-options">
		<app-icon icon="link" v-if="!isLinked" v-tooltip="'Preview not linked'" class="preview-options-item" v-on:click.native="onLinkClick" />
		<app-icon icon="unlink" v-if="isLinked" v-tooltip="'Preview linked'" class="preview-options-item" v-on:click.native="onLinkClick" />
		<app-icon icon="mobile" v-if="isDesktop" v-tooltip="'Switch to Mobile preview'" class="preview-options-item" v-on:click.native="onMobileClick" />
		<app-icon icon="desktop" v-if="isMobile" v-tooltip="'Switch to Desktop preview'" class="preview-options-item" v-on:click.native="onDesktopClick" />
		<app-icon icon="share" v-tooltip="'Share preview URL'" class="preview-options-item" v-on:click.native="onShareClick" />
	</div>

	<div class="preview-device" :class="{'is-mobile': isMobile, 'is-desktop': isDesktop}">

		<iframe :src="url" ref="preview" />

	</div>

</div>

</template>

<script>

import Vue from 'vue'

export default {

	props: ['isManaging'],

	data: function() {
		
		return {
			url: '',
			previewRoute: {
				name: '',
				path: '',
				params: {}
			}
		}

	},

	created: function() {

		this.url = this.$store.getters['config'].preview + '?key=' + this.$store.getters['guide/key'] + '&' + this.$moment().unix()

		window.addEventListener('message', this.onMessage.bind(this))

	},

	beforeDestroy: function() {

		window.removeEventListener('message', this.onMessage.bind(this))

	},

	computed: {

		isLinked: function() {

			return this.$store.getters['linked']

		},

		isDesktop: function() {

			return this.$store.getters['mode'] === 'desktop'

		},

		isMobile: function() {

			return this.$store.getters['mode'] === 'mobile'

		},

		route: function() {

			return this.$route

		}

	},

	watch: {

		isLinked: function(n) {

			if (n) this.onRouteChange()

		},

		route: {
			
			deep: true,
			
			handler: function() {

				this.onRouteChange()

			}

		}

	},

	methods: {

		onShareClick: function() {

			this.$router.push({
				name: 'Share'
			})

		},

		onMobileClick: function() {

			this.$store.commit('mode', 'mobile')

		},

		onDesktopClick: function() {

			this.$store.commit('mode', 'desktop')

		},

		onLinkClick: function() {

			this.$store.commit('linked', !this.isLinked)

		},

		onRouteChange: function() {

			if (!this.isLinked) return

			var group

			if (this.$route.name === 'Categories') {

				if (this.previewRoute.name !== 'Categories') [

					this.$refs.preview.contentWindow.postMessage({
						name: this.$route.name
					}, '*')

				]
				
			} else if (this.$route.name === 'Category') {

				var slug = this.$_.findWhere(this.$store.getters['categories'], {
					id: this.$route.params.category
				}).slug

				if (this.previewRoute.name !== 'Category' || this.previewRoute.params.category !== slug) {

					this.$refs.preview.contentWindow.postMessage({
						name: this.$route.name,
						params: {
							category: slug
						}
					}, '*')

				}

			} else if (this.$route.name === 'Group') {

				group = this.$_.findWhere(this.$store.getters['groups'], {
					id: this.$route.params.group
				})

				if (this.previewRoute.name !== 'Group' || this.previewRoute.params.group !== group.slug) {

					this.$refs.preview.contentWindow.postMessage({
						name: this.$route.name,
						params: {
							category: this.$_.findWhere(this.$store.getters['categories'], {
								id: group.category
							}).slug,
							group: group.slug
						}
					}, '*')

				}

			} else if (this.$route.name === 'Item') {

				var item = this.$_.findWhere(this.$store.getters['items'], {
					id: this.$route.params.item
				})

				group = this.$_.findWhere(this.$store.getters['groups'], {
					id: item.group
				})

				if (this.previewRoute.name !== 'Item' || this.previewRoute.params.item !== item.slug) {

					this.$refs.preview.contentWindow.postMessage({
						name: this.$route.name,
						params: {
							category: this.$_.findWhere(this.$store.getters['categories'], {
								id: group.category
							}).slug,
							group: group.slug,
							item: group.slug
						}
					}, '*')

				}

			}

		},

		onMessage: function(e) {

			Vue.set(this, 'previewRoute', e.data)

			if (!this.isLinked) return

			if (e.data.name === 'Categories') {

				this.$router.push({
					name: 'Categories'
				})				

			} else if (e.data.name === 'Category') {

				this.$router.push({
					name: 'Category',
					params: {
						category: this.$_.findWhere(this.$store.getters['categories'], {
							slug: e.data.params.category
						}).id
					}
				})			

			} else if (e.data.name === 'Group') {

				this.$router.push({
					name: 'Group',
					params: {
						group: this.$_.findWhere(this.$store.getters['groups'], {
							slug: e.data.params.group
						}).id
					}
				})			

			} else if (e.data.name === 'Item') {

				this.$router.push({
					name: 'Item',
					params: {
						item: this.$_.findWhere(this.$store.getters['items'], {
							slug: e.data.params.item
						}).id
					}
				})			

			}

		}

	}

}

</script>

<style scoped>

.preview {
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	left: 0%;
	background-color: #434343;
	background-image: linear-gradient(#5480D3, #3256A7);
	transition: left 300ms ease-in-out;
}

.preview:not(.is-managing) {
	left: 64px;
}

.preview-options {
	position: absolute;
	left: 20px;
	top: 17px;
	height: 32px;
	display: flex;
}

.preview-options-item {
	width: 32px;
	height: 32px;
	margin-right: 8px;
	line-height: 34px;
	text-align: center;
	background-color: #fff;
	color: #4082d3;
	border-radius: 16px;
	font-size: 16px;
    cursor: pointer;
    transition: all 100ms linear;
}

.preview-status {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.75);
	border-radius: 12px;
	padding: 10px;
	right: 20px;
	font-size: 16px;
	top: 20px;
	color: #fff;
	font-weight: 400;
}

.preview.is-managing {
	left: 664px;
}

.preview-background {
	background-color: transparent;
    background-image: 
    linear-gradient(0deg, transparent 9%, 
            rgba(255, 255, 255, .2) 10%, rgba(255, 255, 255, .2) 12%, transparent 13%, transparent 29%, 
            rgba(255, 255, 255, .1) 30%, rgba(255, 255, 255, .1) 31%, transparent 32%, transparent 49%, 
            rgba(255, 255, 255, .1) 50%, rgba(255, 255, 255, .1) 51%, transparent 52%, transparent 69%, 
            rgba(255, 255, 255, .1) 70%, rgba(255, 255, 255, .1) 71%, transparent 72%, transparent 89%,
            rgba(255, 255, 255, .1) 90%, rgba(255, 255, 255, .1) 91%, transparent 92%, transparent),
    linear-gradient(90deg, transparent 9%, 
            rgba(255, 255, 255, .2) 10%, rgba(255, 255, 255, .2) 12%, transparent 13%, transparent 29%, 
            rgba(255, 255, 255, .1) 30%, rgba(255, 255, 255, .1) 31%, transparent 32%, transparent 49%, 
            rgba(255, 255, 255, .1) 50%, rgba(255, 255, 255, .1) 51%, transparent 52%, transparent 69%, 
            rgba(255, 255, 255, .1) 70%, rgba(255, 255, 255, .1) 71%, transparent 72%, transparent 89%,
            rgba(255, 255, 255, .1) 90%, rgba(255, 255, 255, .1) 91%, transparent 92%, transparent);
    
    height:100%;
	background-size:50px 50px;
}

.preview-device {
	position: absolute;
	box-sizing: initial;
	background-color: #fff;
	overflow: hidden;
	z-index: 1;
	border-radius: 16px;
	border: 6px solid #000;
	box-shadow: 0 4px 24px 0 rgb(0 0 0 / 25%);
}

.preview-device.is-desktop {
	left: 8px;
	top: 64px;
	right: 8px;
	bottom: 8px;
}

.preview-device.is-mobile {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 375px;
	max-height: 667px;
	height: calc(100% - 64px);
}

.preview-device iframe {
	width: 100%;
	height: 100%;
}

</style>
